<template>
  <div class="config-integrations-zabbix-create">
    <h1 class="titulo-card fs-4">Integrações / Zabbix / Dados da integração</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <h5 class="text-info mb-0">{{ integration.description }}</h5>
          <p class="mb-0">
            <strong>Ativo: </strong> {{ integration.active }}
          </p>
          <p class="mb-0">
            <strong>Endpoint: </strong> {{ integration.endpoint }}
          </p>
          <p class="mb-0">
            <strong>Usuário: </strong> {{ integration.login }}
          </p>
          <p class="mb-0">
            <strong>Senha: </strong> {{ integration.password }}
          </p>
        </div>
        <div class="col-12">
          <div class="d-grid gap-2">
            <button type="button" @click="btnDeleteClick()" class="btn btn-outline-danger">
              <font-awesome-icon :icon="['fas', 'trash']" fixed-width class="me-1"/>
              Remover
            </button>
            <button type="button" @click="btnEditClick()" class="btn btn-primary">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width class="me-1"/>
              Editar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import loading from '@/modules/loading';
import dialogo from '@/modules/dialogo';

export default {
  name: 'ConfigIntegrationsZabbixView',
  data() {
    return {
      integrationId: undefined,
      integration: {
        active: true,
        description: '',
        endpoint: '',
        login: '',
        password: '',
      }
    }
  },

  methods: {
    ////////////////////////////////
    // module funcs
    load() {
      loading(true);
      api.get(`/integrations/zabbix/${this.integrationId}`).then(res => {
        loading(false);
        this.integration = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
        this.$router.go(-1);
      });
    },

    delete() {
      loading(true);
      api.delete(`/integrations/zabbix/${this.integrationId}`).then(() => {
        loading(false);
        this.$router.go(-1);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    // module funcs
    ////////////////////////////////


    ////////////////////////////////
    // interface item click handlers
    btnDeleteClick() {
      dialogo.confirmacao(`Confirma remover a integração <b>${this.integration.description}</b>?`).then(() => {
        this.delete();
      });
    },
    btnEditClick() {
      this.$router.push(`/configuracoes/integracoes/zabbix/${this.integrationId}/editar`);
    },
    // interface item click handlers
    ////////////////////////////////

  },
  created() {
    const integrationId = this.$route.params.integrationId;

    if (!integrationId) {
      this.$router.push({ path: `/configuracoes/integracoes` });
      return;
    }
    this.integrationId = integrationId;

    this.load();
  }
}


</script>
